import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

const ShimmerWrapper = styled(Box)({
  padding: 8,
  justifyContent: 'center'
});

const Shimmer = () => {
  return (
    <ShimmerWrapper>
      <Skeleton variant="text" width={"75%"}/>
      <Skeleton variant="rectangle" height={18} width={"60%"}/>
    </ShimmerWrapper>
  );
};

export default Shimmer;