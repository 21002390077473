import { PropTypes } from 'prop-types';
import { DefinedRange as RDRDefinedRange } from 'react-date-range';
import { customStaticRanges, rangeColors } from '../../../constants/dateRangePicker';

const DefinedRange = ({onChange, ranges}) => {
  return (
    <RDRDefinedRange
      onChange={onChange}
      ranges={ranges}
      inputRanges={[]}
      staticRanges={customStaticRanges}
      rangeColors={rangeColors}
      className="cdrp-swap"
    />
  );
};

DefinedRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  ranges: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DefinedRange;