import { embed } from '@nebula.js/stardust';

import barchart from '@nebula.js/sn-bar-chart';
import linechart from '@nebula.js/sn-line-chart';
import piechart from '@nebula.js/sn-pie-chart';
import mekkochart from '@nebula.js/sn-mekko-chart';
import kpi from '@nebula.js/sn-kpi';
import combochart from '@nebula.js/sn-combo-chart';
import table from '@nebula.js/sn-table';
import actionButton from '@nebula.js/sn-action-button';

import mainTheme from './mainTheme';

const themes = [
  {
    id: 'mainTheme',
    load: () => Promise.resolve(mainTheme),
  }
];

const types = [
  {
    name: 'kpi',
    load: () => Promise.resolve(kpi),
  },
  {
    name: 'piechart',
    load: () => Promise.resolve(piechart),
  },
  {
    name: 'barchart',
    load: () => Promise.resolve(barchart),
  },
  {
    name: 'linechart',
    load: () => Promise.resolve(linechart),
  },
  {
    name: 'combochart',
    load: () => Promise.resolve(combochart),
  },
  {
    name: 'mekkochart',
    load: () => Promise.resolve(mekkochart),
  },
  {
    name: 'table',
    load: () => Promise.resolve(table),
  },
  {
    name: 'action-button',
    load: () => Promise.resolve(actionButton),
  }
];

const configure = embed.createConfiguration({
  types,
  themes,
  context: {
    theme: 'mainTheme',
    language: 'en-US',
  },
});

export default configure;
