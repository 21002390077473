import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Layout from '../organisms/layout';
import Overview from '../pages/overview';
import TableReport from '../pages/tableReport';
import Glossary from '../pages/glossary';
import Faq from '../pages/faq';
import NoMatch from '../pages/noMatch';
import Utils from '../pages/utils';
// import AnonymousView from '../pages/anonymousView';

const CustomRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={ <Layout /> }>
          <Route index element={ <Overview /> } />
          <Route path="report" element={ <TableReport /> } />
          <Route path="utils" element={ <Utils /> } >
            <Route path="glossary" element={ <Glossary /> } />
            <Route path="faq" element={ <Faq /> } />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default CustomRoutes;