import { PropTypes } from 'prop-types';
import Button from '@mui/material/Button';

const IconLabelButton = ({children, onClick, ...props}) => {
  return (
    <Button { ...props }
      onClick={onClick}
      sx={{
        backgroundColor: 'white',
        color: 'onSurface.main',
        textTransform: 'capitalize',
        fontWeight: 400,
        border: '1px solid',
        borderColor: 'surface.dark',
        boxShadow: '0px 2px 4px 0px #D2D2D24D',
        paddingLeft: 4,
        paddingRight: 4,
        borderRadius: 1,

        '&:hover': {
          backgroundColor: 'white',
          borderColor: 'onSurface.light',
          boxShadow: '1px 3px 6px 1px #D2D2D2BF'
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: 'surface.light',
          borderColor: 'primary.main',
        },
      }}>
      { children }
    </Button>
  );
}

IconLabelButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  props: PropTypes.object
};

export default IconLabelButton;