import { useContext } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Qlik
import GlobalContext from '../../qlik/Context';

// Helper
import { sortAlphabetically } from '../../helper';

// Atomic
import PageHeader from '../atoms/pageHeader';
import CustomLink from '../atoms/customLink';


const HeadingTerm = styled(Typography)({
  fontSize: 24,
  fontWeight: 500,

  marginTop: '48px',
  marginBottom: '16px',
});

const glossaryTerms = [
  {
    name: 'Response Code',
    definition: 'Payment Transaction Response Codes are standard IDs that represent transaction properties.',
    readMore: false
  },
  {
    name: 'Payment Method',
    definition: `
      Is the way that customers pay for a product or service. The payment methods may include cards, ted, boleto or pix.
    `,
    readMore: false
  },
  {
    name: 'MCC',
    definition: `
      Merchant Category Code is a four-digit number for retail financial services.
      An MCC is used to classify a business by the types of goods or services it provides.
    `,
    readMore: false
  },
  {
    name: 'KPI',
    definition: `
      Key Performance Indicator. It's a measure of one or more essential metrics to the business.
      In the app context, it's a visual representation of such performance indicators of the Companie.
    `,
    readMore: 'faq#kpi'
  }
];

const Glossary = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { lastUpdate, user } = useContext(GlobalContext);

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 3, px: isSmallScreen ? 2 : 5, width: '100%', color: 'primary.main' }}>
      <PageHeader title={user} lastUpdate={lastUpdate}/>
      {
        sortAlphabetically(glossaryTerms, 'name').map((term, i) => {
          return (
            <span key={i}>
              <HeadingTerm> { term.name } </HeadingTerm>
              <span> { term.definition } </span>
              { term.readMore ? <CustomLink href={term.readMore}> Learn more </CustomLink> : null }
            </span>
          );
        })
      }
    </Box>
  );
}

export default Glossary;