import { useEffect, useState } from 'react';

import { Link } from "react-router-dom";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const navigation = [
  { name: "Overview", path: "/" },
  { name: "Report", path: "report" },
];

const ScrollableTabsLink = ({sx}) => {
  const getCurrentUrl = () => {
    if (window.location.pathname === "/") return 0;
    if (window.location.pathname === "/report") return 1;
    return false; 
  }

  const [value, setValue] = useState(getCurrentUrl());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      aria-label="scrollable auto tabs"
      sx={{minHeight: 64, ...sx}}
    >
      {
        navigation.map(({name, path}) =>
          <Tab
            label={name}
            component={Link}
            to={path}
            key={name}
            aria-controls={`tabpanel-${name}`}
            sx={{minHeight: 64, textTransform: 'Capitalize'}}
          />
        )
      }
    </Tabs>
  );
}

export default ScrollableTabsLink;