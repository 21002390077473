import { PropTypes } from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useTheme, styled } from '@mui/material/styles';
import SavingsIcon from '@mui/icons-material/Savings';
import RecommendIcon from '@mui/icons-material/Recommend';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Qlik
import GlobalContext from '../../qlik/Context';
import {getLayoutText} from '../../qlik/models';

// Atomic
import InfoTooltip from '../atoms/infoTooltip';
import Shimmer from '../atoms/shimmer';


const kpisInfo = [
  'TPV-In is the sum of the approved Funds In transactions in the period.',
  'TPV-Out is sum of the Approved Transactions for Goods Purchase, Purchase Phone Authorized, Cash Withdrawal, E-Commerce, Ted, Boleto and Pix in the period.',
  'The count of Approved Cards Transactions in the period',
  'The count of Denied Cards Transactions in the period',
  'The distinct count of accounts that made any transactions in the period',
  'The distinct count of operating clients. This count is based on the number of operating treasury account id in the period.',
  'The mensal Average TPV-Out by Account is calculated as the amount of TPV-Out in the period divided by the total Operating Accounts in the same period. Then, the result is divided by the number of months in the period ',
  'Average mensal Growth is calculated as the average of the percentage growth of TPV-Out by month (exception for the current month) in the period.',
  'The Conversion Rate is calculated as the count of Approved Cards Transactions in the period divided by the total count transactions in the same period.',
  'Average Ticket is calculated as the sum of approved TPV-Out in the period divided by the count of the Approved Transactions in the same period. ',
];


const CustomPaper = styled(Paper, { shouldForwardProp: (prop) => prop !== 'hasSecondary' })
  (({ theme, hasSecondary }) => ({
    position: 'relative',
    width: 'auto',
    padding: '8px',
    border: '1px solid',
    borderColor: theme.palette.surface.dark,
    height: hasSecondary ? 136 : 120
}));

const MainKpiWrapper = styled('span')({
  display: 'flex',
  height: 72
});

const SecondaryKpiWrapper = styled('span', { shouldForwardProp: (prop) => prop !== 'loading' })
(({ theme, loading }) => ({
  padding: '0 8px',
  marginTop: 12,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  color: theme.palette.onSurface.middle,
  display: loading ? 'none' : 'block',
  visibility: loading ? 'hidden' : 'visible'
}));


const KpiBlock = () => {
  const {app, nebula} = useContext(GlobalContext);
  const [kpis, setKpis] = useState([]);
  const [accumKpis, setAccumKpis] = useState({});
  const [loading, setLoading] = useState(true);

  const destroyOldVisualizations = () => {
    if (!kpis.length) return;

    kpis.forEach(visualization => visualization.then(v => v.destroy()));
    setKpis([]);
  };

  const getAccumulatedKpis = async () => {
    if (!app) return;

    const tpvIn = await getLayoutText(app, 'nsfSNPg');
    const tpvOut = await getLayoutText(app, 'gdkRQK');

    const approvedTxns = await getLayoutText(app, 'TMtdZL');
    const deniedTxns = await getLayoutText(app, 'xUzHrc');

    setAccumKpis({
      tpvIn,
      tpvOut,
      approvedTxns,
      deniedTxns
    });
  };

  const getKpis = () => {
    destroyOldVisualizations();
    setLoading(false);

    const tpvInPeriod = nebula.render({ element: document.getElementById('k1'), id: 'dd13662d-272d-4a66-99ef-498ace0f2903' });

    const tpvOutPeriod = nebula.render({ element: document.getElementById('k2'), id: 'a541fb8c-f4a9-4c17-a38d-ef3f27b30064' });

    const approvedTxnsPeriod = nebula.render({ element: document.getElementById('k3'), id: 'd35e7747-430e-4fcc-8c0f-b3503ddd7170' });
    
    const deniedTxnsPeriod = nebula.render({ element: document.getElementById('k4'), id: 'c051c79c-38e6-444f-90bd-55835b662534' });

    const accountsOps = nebula.render({ element: document.getElementById('k5'), id: 'e751bc0d-08c1-4a97-9ae5-5d73efcfe649' });

    const clientsOps = nebula.render({ element: document.getElementById('k6'), id: 'c09715c7-e0b2-410f-8d3e-707d0f97474b' });

    const tpvByAccount = nebula.render({ element: document.getElementById('k7'), id: '42e61df1-4cf4-421d-a8dc-0a90f0df5179' });
        
    const avgGrowthByMonth = nebula.render({ element: document.getElementById('k8'), id: '94e4c7e8-f52d-49b7-81c1-2fcc659fe6f7' });

    const conversion = nebula.render({ element: document.getElementById('k9'), id: '7c76ef0d-0bc8-4547-abc5-84e4437b6da5' });
    
    const avgTicket = nebula.render({ element: document.getElementById('k10'), id: '5e122a17-292a-44f1-a79d-e50734442bde' });

    setKpis([
      tpvInPeriod,
      tpvOutPeriod,
      accountsOps,
      clientsOps,
      tpvByAccount,
      avgGrowthByMonth,
      approvedTxnsPeriod,
      deniedTxnsPeriod,
      conversion,
      avgTicket
    ]);
  };

  useEffect(() => {
    getAccumulatedKpis();
  }, [app]);

  useEffect(() => {
    if (nebula) {
      getKpis();
    }
  }, [nebula]);

  return (
    <Grid container spacing={2} sx={{mt: 1}}>
      <Grid item xs={12} sm={6} key="0" >
        <CustomPaper elevation={3} hasSecondary>
          <InfoTooltip disableHoverListener={loading} title={kpisInfo[0]}/>
            { loading && <Shimmer /> }
            <MainKpiWrapper id={`k1`}></MainKpiWrapper>
            <SecondaryKpiWrapper loading={loading}> <SavingsIcon sx={{fontSize: 16, color: 'onSurface.light'}}/> <b>{ accumKpis.tpvIn }</b> Accum. </SecondaryKpiWrapper>
        </CustomPaper>
      </Grid>
      <Grid item xs={12} sm={6} key="1">
        <CustomPaper elevation={3} hasSecondary>
          <InfoTooltip disableHoverListener={loading} title={kpisInfo[1]}/>
            { loading && <Shimmer /> }
            <MainKpiWrapper id={`k2`}></MainKpiWrapper>
            <SecondaryKpiWrapper loading={loading}> <SavingsIcon sx={{fontSize: 16, color: 'onSurface.light'}}/> <b>{ accumKpis.tpvOut }</b> Accum. </SecondaryKpiWrapper>
        </CustomPaper>
      </Grid>

      <Grid item xs={12} sm={6} key="2">
        <CustomPaper elevation={3} hasSecondary>
          <InfoTooltip disableHoverListener={loading} title={kpisInfo[2]}/>
            { loading && <Shimmer /> }
            <MainKpiWrapper id={`k3`}></MainKpiWrapper>
            <SecondaryKpiWrapper loading={loading}> <CheckCircleIcon sx={{fontSize: 16, color: 'secondary.light'}}/> <b>{ accumKpis.approvedTxns }</b> Accum. </SecondaryKpiWrapper>
        </CustomPaper>
      </Grid>
      <Grid item xs={12} sm={6} key="3">
        <CustomPaper elevation={3} hasSecondary>
          <InfoTooltip disableHoverListener={loading} title={kpisInfo[3]}/>
          { loading && <Shimmer /> }
          <MainKpiWrapper id={`k4`}></MainKpiWrapper>
          <SecondaryKpiWrapper loading={loading}> <CancelIcon sx={{fontSize: 16, color: 'accent.light'}}/> <b>{ accumKpis.deniedTxns }</b> Accum. </SecondaryKpiWrapper>
        </CustomPaper>
      </Grid>

      {[5, 6, 7, 8, 9, 10].map((i) => (
        <Grid item xs={12} sm={6} lg={4} key={i}>
          <CustomPaper elevation={3}>
            <InfoTooltip disableHoverListener={loading} title={kpisInfo[i-1]}/>
            { loading && <Shimmer /> }
            <MainKpiWrapper id={`k${i}`}></MainKpiWrapper>
          </CustomPaper>
        </Grid>
      ))}
    </Grid>
  );
};

export default KpiBlock;