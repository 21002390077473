import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';


const TooltipIcon = styled(HelpIcon)(
  ({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 16,
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: theme.zIndex.speedDial
}));

const infoTooltip = ({title, disableHoverListener}) => {
  return (
    <Tooltip arrow title={title} disableHoverListener={disableHoverListener} placement="top">
      <TooltipIcon/>
    </Tooltip>
  );
}

export default infoTooltip;