import { styled } from '@mui/material/styles';

import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';


const styles = {
  icon: {
    marginRight: '8px',
    fontSize: 16
  },
};

const UserTipContainer = styled('div')(
  ({theme}) => ({
    borderRadius: 32,
    border: '1px solid',
    borderColor: theme.palette.onSurface.light,
    color: theme.palette.onSurface.dark,
    padding: 6,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
}));

const UserTip = ({children, type, style}) => {

  const fromType = (type) => {
    switch(type) {
      case "question": return <HelpIcon sx={styles.icon}/>
      case "insight": return <TipsAndUpdatesIcon sx={styles.icon}/>
      case "info": return <InfoIcon sx={styles.icon}/>
    }
  }

  return (
    <UserTipContainer style={style}>
      {[
        fromType(type),
        children
      ]}
    </UserTipContainer>
  );
}

export default UserTip;