import {useContext, useState, useEffect} from 'react';

import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Qlik
import GlobalContext from '../../qlik/Context';
import {handleFilter} from '../../qlik/models';

// Atomic
import DefinedRange from '../atoms/definedRange';
import CustomRange from '../atoms/customRange';

// Helper
import { formatDate, today } from '../../helper';


const TabPanel = styled('div')(
  ({value, index}) => ({
    height: 385,

    visibility: value !== index ? 'hidden' : 'visible', 
    display: value !== index ? 'none' : 'block', 
    
    '& .cdrp-swap': {
      width: '100%'
    },

    '& .cdrp-swap .rdrMonth': {
      width: '100%',
    }
}));

const CalendarWrapper = styled(Box)({
  display: 'flex',
});


const CustomDateRangePicker = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { filters, app } = useContext(GlobalContext);
  const { dateFilter, setDateFilter } = filters;

  const [tab, setTab] = useState(0);

  const handleDateChange = ({selection}) => {
    handleFilter(selection, app);
    setDateFilter([selection]);
  };

  const handleMobileTabChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    isSmallScreen ? 
      <>
        <Tabs value={tab} onChange={handleMobileTabChange} aria-label="calendar range">
          <Tab label="defined range"/>
          <Tab label="custom range"/>
        </Tabs>
        <TabPanel value={tab} index={0}>
          <DefinedRange onChange={handleDateChange} ranges={dateFilter}/>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <CustomRange onChange={handleDateChange} ranges={dateFilter} maxDate={today()}/>
        </TabPanel>
      </>
    :
      <CalendarWrapper>
        <DefinedRange onChange={handleDateChange} ranges={dateFilter}/>
        <CustomRange onChange={handleDateChange} ranges={dateFilter} maxDate={today()}/>
      </CalendarWrapper>
  );
};

export default CustomDateRangePicker;