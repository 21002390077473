import { PropTypes } from 'prop-types';

import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const staticMixin = (theme) => ({
  width: 200, 
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.onSurface.middle,
  fontWeight: 600,

  '& .MuiBadge-badge': {
    transform: 'none',
    position: 'static',
    marginLeft: '8px'
  },
});

const floatMixin = (theme) => ({
  fontWeight: 600,
});

const StyledBadge = styled(Badge, { shouldForwardProp: (prop) => prop !== 'type'})(
  ({ theme, type }) => ({
    ...(type === 'static' && { ...staticMixin(theme) }),
    ...(type === 'float' && { ...floatMixin() }),
}));

const TextAndBadge = ({children, color = "grey", type = "float"}) => {
  return (
    <StyledBadge variant="dot" type={type} color={color}>
      {children}
    </StyledBadge>
  );
};


TextAndBadge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]),
  type: PropTypes.string,
};

export default TextAndBadge;