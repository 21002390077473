import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PageTitleTypography = styled(Typography)(
  ({theme}) => ({
    color: theme.palette.onSurface.main,
    fontWeight: '900',
    fontSize: 20,
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(3)
}));

const PageTitle = ({title = '', subtitle = ''}) => {
  return (
    <PageTitleTypography>
      <span style={{fontWeight: '500'}}> { title } </span>
      <span style={{fontWeight: '300'}}> { subtitle } </span>
    </PageTitleTypography>
  );
};

export default PageTitle;