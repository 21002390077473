import { PropTypes } from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { Avatar as MuiAvatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import logos from '../../../constants/logos';


const openedMixin = (theme) => ({
  width: 64,
  height: 64,
  transition: theme.transitions.create(['width', 'height'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create(['width', 'height'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: 40,
  height: 40,
});

const Avatar = ({open, name = ""}) => {
	const theme = useTheme();

	const extractFirstLetter = (string) => {
		return string.charAt(0);
	};

  return (
    <Box sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
			marginTop: '48px',
			marginBottom: '32px'
    }}>
			<MuiAvatar
				src={logos[name]}
				sx={{
					bgcolor: 'onSurface.light',
					...(open && { ...openedMixin(theme) }),
					...(!open && { ...closedMixin(theme) })
			}}>
				{/* { extractFirstLetter(name) } */}
				<Skeleton variant="circular" width={64} height={64} />
			</MuiAvatar>
			{
				open &&
				<>
					<Typography sx={{ color: 'onSurface.main', mt: 1, textTransform: 'capitalize' }}>
							{ name }
					</Typography>
					<Typography sx={{ color: 'surface.main', ...theme.typography.body2 }}>
							User
					</Typography>
				</>
			}
    </Box>
  );
};

Avatar.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string,
};

export default Avatar;