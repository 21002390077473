import { PropTypes } from 'prop-types';
import MuiLink from '@mui/material/Link';
import { styled } from '@mui/material/styles';


const Link = styled(MuiLink)(
  ({theme}) => ({
    '&:hover': {
      color: theme.palette.primary.light
    }
}));


const CustomLink = ({children, href}) => {
  return (
    <Link href={href} color="#7F9DB6"> { children } </Link> 
  );
}

CustomLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
  href: PropTypes.string.isRequired
};


export default CustomLink;