const today = () => {
  return new Date();
};

const formatDate = (date) => {
  return date.toLocaleDateString('en-US');
};

const getCurrentDate = () => {
  return formatDate(today());
};

const preventNull = (param) => {
  return param ?? '';
};

const isEmail = (email) => {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
};

const isEmptyString = (string) => {
  return ( (!string) || (string === null) || (string === '') || (string.length === 0) || (/^\s*$/.test(string)) );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0) + string.slice(1).toLowerCase();
}

const sortAlphabetically = (string, key) => {
  return string.sort((a, b) => (a[`${key}`] || "").localeCompare((b[`${key}`] || "")));
};

const getLocationHash = () => {
  return window.location.hash.split("#")[1];
};

export {
  today,
  formatDate,
  getCurrentDate,
  preventNull,
  isEmail,
  isEmptyString,
  capitalizeFirstLetter,
  sortAlphabetically, 
  getLocationHash
}