import {formatDate} from '../helper';

const getLayoutText = async (app, id) => {
  const genericObject = await app?.getObject(id);
  const {qHyperCube} = await genericObject?.getLayout({});

  return qHyperCube?.qGrandTotalRow[0].qText;
};

const clearQlikFilters = async (app) => {
  return await app?.clearAll({ "qLockedAlso": true });
};

const getTableContent = async (app, id) => {
  const genericObject = await app?.getObject(id);
  const [{qMatrix}] = await genericObject?.getHyperCubeData({
    "qPath": "/qHyperCubeDef",
    "qPages": [{
      "qLeft": 0,
      "qTop": 0,
      "qWidth": 10,
      "qHeight": 1000
    }]
  });

  return qMatrix;
};

const handleFilter = async ({startDate, endDate}, app) => {
  const dateModel = await app?.getField("Date");
  return await dateModel?.select({ "qMatch": `>=${formatDate(startDate)} <=${formatDate(endDate)}`});
};

export {
  getLayoutText,
  clearQlikFilters,
  getTableContent,
  handleFilter
};