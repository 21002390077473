import { PropTypes } from 'prop-types';
import {useState} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme, styled } from '@mui/material/styles';

// Atomic
import TextAndBadge from '../atoms/textAndBadge';
import SmallTitle from '../atoms/smallTitle';
import Emoji from '../atoms/emoji';

// Assets
import {ReactComponent as ConversionRateIcon} from '../../img/conversionRateIcon.svg';
import {ReactComponent as WeeklyComparisonIcon} from '../../img/weeklyComparisonIcon.svg';
import {ReactComponent as NewClientsIcon} from '../../img/newClientsIcon.svg';
import {ReactComponent as DecreaseIcon} from '../../img/decreaseIcon.svg';
import {ReactComponent as IncreaseIcon} from '../../img/increaseIcon.svg';


const cautionMixin = (theme) => ({
  color: theme.palette.caution[30],
  backgroundColor: theme.palette.caution[85],
});

const warningMixin = (theme) => ({
  color: theme.palette.accent[30],
  backgroundColor: theme.palette.accent[85],
});

const AlertsMenu = styled(Menu)(
  ({ theme }) => ({
    '& .MuiList-root': {
      paddingBottom: 0,
    },

    '& .MuiPaper-root': {
      maxWidth: 486,
      border: '1px solid',
      borderColor: theme.palette.surface.dark,
    },

    [theme.breakpoints.down("sm")]: {
      top: 16,

      '& .MuiPaper-root': {
        maxWidth: '95%',
        right: 4,
        left: 4,
        margin: 'auto'
      },
    }
}));

const MenuHeader = styled('div')(
  ({theme}) => ({ 
    display: 'flex', 
    padding: '12px 16px 16px',
    borderBottom: '1px solid',
    borderColor: theme.palette.surface.dark,
}));

const AlertWrapper = styled('div')(
  ({theme}) => ({ 
    padding: '16px 24px',
    borderBottom: '1px solid',
    borderColor: theme.palette.surface.dark,
}));

const AlertHeader = styled(Box)({
  position: 'relative',
  marginBottom: '20px'
});

const AlertBody = styled(Box)({
  display: 'flex'
});

const AlertAvatar = styled(Avatar, {shouldForwardProp: (prop) => prop !== 'status'})(
  ({theme, status}) => ({
    marginRight: '20px',
    height: 50,
    width: 50,

    ...(status === 'caution' && {
      backgroundColor: theme.palette.caution[85],
      '& path': { fill:  theme.palette.caution.main }
    }),

    ...(status === 'warning' && {
      backgroundColor: theme.palette.accent[85],
      '& path': { fill:  theme.palette.accent.main }
    }),

    ...(status === 'good' && {
      backgroundColor: theme.palette.secondary[85],
      '& path': { fill:  theme.palette.secondary.main }
    }),

    ...(status === 'info' && {
      backgroundColor: theme.palette.aux[85],
      '& path': { fill:  theme.palette.aux.main }
    }),
}));

const AlertTitle = styled(Typography)(
  ({theme}) => ({
    fontSize: 18,
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginBottom: 4
}));

const AlertContent = styled(Box)({
  flex: 2
});

const AlertDesc = styled(Typography)(
  ({theme}) => ({
    fontSize: 14,
    color: theme.palette.onSurface.middle,
    fontWeight: 500,
    maxWidth: 320,
    marginBottom: 14,
    lineHeight: '20px'
}));

const ComparisonValue = styled('span', { shouldForwardProp: (prop) => prop !== 'status' })(
  ({theme, status}) => ({
    marginRight: 8,

    ...(status === 'caution' && {
      color: theme.palette.caution.main,
      '& path': { fill:  theme.palette.caution.main }
    }),

    ...(status === 'warning' && {
      color: theme.palette.accent.main,
      '& path': { fill:  theme.palette.accent.main }
    }),

    ...(status === 'good' && {
      color: theme.palette.secondary.main,
      '& path': { fill:  theme.palette.secondary.main }
    }),
}));

const DescComparisonValue = styled('span', { shouldForwardProp: (prop) => prop !== 'status' })(
  ({theme, status}) => ({
    margin: '0 4px',
    fontSize: 16,
    fontWeight: 800,

    ...(status === 'caution' && {
      color: theme.palette.caution.main,
    }),

    ...(status === 'warning' && {
      color: theme.palette.accent.main,
    }),

    ...(status === 'good' && {
      color: theme.palette.secondary.main,
    }),
}));

const StatusSticker = styled(Box, { shouldForwardProp: (prop) => prop !== 'status' })(
  ({theme, status}) => ({
    fontSize: 13,
    fontWeight: '400',
    textTransform: 'uppercase',
    width: 'fit-content',
    maxWidth: 100,
    borderRadius: 8,
    padding: '6px 16px',
    position: 'absolute',
    top: 0,
    right: 0,
    ...(status === 'caution' && { ...cautionMixin(theme) }),
    ...(status === 'warning' && { ...warningMixin(theme) }),
}));

const NewClientsUl = styled('ul', {shouldForwardProp: (prop) => prop !== 'seeMore'})(
  ({theme, seeMore}) => ({
    listStyle: 'disc',
    paddingLeft: 30,
    marginBottom: 14,
    fontSize: 14,
    fontWeight: 300,
    
    display: seeMore ? 'block' : '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',


    '& li': {
      color: theme.palette.onSurface.dark,
    }
}));

const TextButton = styled('button')(
  ({theme}) => ({
    background: 'transparent',
    border: 'none',
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.aux.main,
    textDecoration: 'underline',
    fontSize: 14,
    marginLeft: 'auto',

  '&:hover': {
    color: theme.palette.primary.light
  }
}));

const conversionTargetMeter = (value)  => {
  if (value[0] === '-') return value.slice(1);
};

const translateValue = (value) => {
  if (value[0] === '-') return `${value.slice(1)} lower`;

  return `${value} higher`;
};


const AlertsAndInsights = ({notifications, statusColor, anchorEl, open, handleClose}) => {
	const theme = useTheme();
  const [seeMore, setSeeMore] = useState(false);

  const handleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  return (
    <AlertsMenu
      id="notifications-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{ 'aria-labelledby': 'notification-item' }}
    >
      <MenuHeader> 
        <TextAndBadge type="static" color={statusColor}>Notifications</TextAndBadge>
      </MenuHeader>

      { (notifications?.conversionRate?.status !== "good") ?
          <AlertWrapper>
          <AlertHeader>
            <SmallTitle>CONVERSION RATE</SmallTitle>
            <StatusSticker status={notifications?.conversionRate?.status}> {notifications?.conversionRate?.status} </StatusSticker>
          </AlertHeader>
          <AlertBody>
            <AlertAvatar status={notifications?.conversionRate?.status}>
              <SvgIcon viewBox='0 0 30 30' style={{fontSize: 30}}> <ConversionRateIcon /> </SvgIcon>
            </AlertAvatar>
            <AlertContent>
              <AlertTitle>
                <ComparisonValue status={notifications?.conversionRate?.status}>
                  {conversionTargetMeter(notifications?.conversionRate?.value)}
                </ComparisonValue>
                Below Target
              </AlertTitle>
              {
                notifications?.conversionRate?.status === 'caution' ?
                  <AlertDesc>
                    Keep an eye on your <b>Denied Transactions</b>. Your conversion rate is below 85% in this period
                  </AlertDesc>
                : 
                  <AlertDesc>
                    Warning! Your conversion rate in this period is below 65%. Check the reasons for <b>Denied Transactions</b> and the <b>detractors MCCs</b>
                  </AlertDesc>  
              }
            </AlertContent>
          </AlertBody>
        </AlertWrapper> 
      : null }

      <AlertWrapper>
        <AlertHeader>
          <SmallTitle>WEEKLY COMPARISON</SmallTitle>
        </AlertHeader>
        <AlertBody>
          <AlertAvatar status={notifications?.weeklyComparison?.status}>
            <SvgIcon viewBox='0 0 30 30' style={{fontSize: 30}}> <WeeklyComparisonIcon /> </SvgIcon>
          </AlertAvatar>
          <AlertContent>
            <AlertTitle>
              <ComparisonValue status={notifications?.weeklyComparison?.status}>
                <SvgIcon viewBox='0 0 30 30' style={{fontSize: 12, marginRight: 8}}>
                  { notifications?.weeklyComparison?.status === 'good' ?
                    <IncreaseIcon title="Increase Icon"/>
                  :
                    <DecreaseIcon title="Decrease Icon"/>
                  }
                </SvgIcon>
                {notifications?.weeklyComparison?.value}
              </ComparisonValue>
              Vs D-7
            </AlertTitle>
            <AlertDesc>
              Your amount of <b>TPV-Out</b> this week is
              <DescComparisonValue status={notifications?.weeklyComparison?.status}>
                { translateValue(notifications?.weeklyComparison?.value) } 
              </DescComparisonValue>
              than the same period in the previous week.
            </AlertDesc>
          </AlertContent>
        </AlertBody>
      </AlertWrapper>

      { notifications?.newClients?.value ?
        <AlertWrapper>
          <AlertHeader>
            <SmallTitle>NEW CLIENTS</SmallTitle>
          </AlertHeader>
          <AlertBody>
            <AlertAvatar sx={{bgcolor: theme.palette.aux[85]}}>
              <SvgIcon viewBox='0 0 30 30' style={{fontSize: 30}}> <NewClientsIcon /> </SvgIcon>
            </AlertAvatar>
            <AlertContent>
              <AlertDesc>
                You have <span style={{fontSize: 18, fontWeight: 800}}>{notifications?.newClients?.value}</span> new clients in this week! <Emoji symbol={"🎉"}/>
              </AlertDesc>
              <NewClientsUl seeMore={seeMore}>
                { notifications?.newClients?.extraContent?.map((client, i) => (
                  <li key={i}>{client[0].qText}</li>
                )) }
              </NewClientsUl>
              { (notifications?.newClients?.value > 3) ?
                <TextButton onClick={handleSeeMore}>See {seeMore ? 'less' : 'more'}</TextButton>
              : null }
            </AlertContent>
          </AlertBody>
        </AlertWrapper>
      : null }
      
    </AlertsMenu>
  );
};

AlertsAndInsights.propTypes = {
  notifications: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  statusColor: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Element)
};

export default AlertsAndInsights;