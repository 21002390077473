import { useContext } from 'react';

import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Qlik
import GlobalContext from '../../qlik/Context';

// Atomic
import PageHeader from '../atoms/pageHeader';
import PageTitle from '../atoms/pageTitle';
import FaqAccordion from '../organisms/faqAccordion';
import UserTip from '../atoms/userTip';

// Assets
import filterAnatomy from '../../img/filterAnatomy.svg';


const DateFilterImg = styled('img')({
  maxWidth: '100%'
});

const ListItem = styled('li')(
  ({theme}) => ({
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),

    '& span:first-of-type': {
      fontWeight: '700',
      fontSize: 18,
    },

    '& div:first-of-type': {
      color: theme.palette.onSurface.dark,
    },

    [theme.breakpoints.down('xl')]: {
      '& span:first-of-type': {
        fontSize: 16,
      },
  
      '& div:first-of-type': {
        fontSize: 14
      },
    }
}));

const DescSecondaryContent = styled('div')(
  ({theme}) => ({
    color: theme.palette.onSurface.dark,
    fontSize: 12,

    '& span:first-of-type': {
      fontWeight: '500',
      fontSize: 'inherit', 
    }
}));

const Faq = () => {
  const {lastUpdate, user} = useContext(GlobalContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const styles = {
    contentBox: {
      flexGrow: 1,
      py: 3,
      px: isSmallScreen ? 2 : 5,
      width: '100%'
    },
  };

  return (
    <Box component="main" sx={styles.contentBox}>
      <PageHeader title={user} lastUpdate={lastUpdate}/>
      <FaqAccordion />

      <PageTitle title="Filter by Date" subtitle="- anatomy" />

      <Grid container spacing={2} id="faq#filters">
        <Grid item xs={12} lg={6}>
          <DateFilterImg src={filterAnatomy} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ol>
            <ListItem>
              <span> Pre-defined Ranges </span>
              <div> Filters data by selecting one of the pre-defined ranges. </div>
            </ListItem>
            <ListItem>
              <span> Manual Inputs </span>
              <div> Filters data by manually inputing a start date and end date. </div>
              <DescSecondaryContent> (format <span>MUST BE</span> "Mon DD, YYYY") </DescSecondaryContent>
            </ListItem>
            <ListItem>
              <span> Date Range Picker </span>
              <div> Filters data by picking a range from the calendar. </div>
              <DescSecondaryContent> (single dates are also supported.) </DescSecondaryContent>
            </ListItem>
          </ol>
          <UserTip style={{marginTop: '32px'}} type="info"> <span> Date filters affects <b> exclusively </b> the "Overview" tab. </span> </UserTip>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Faq;