import { useState, useContext } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiDrawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DataObjectIcon from '@mui/icons-material/DataObject';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import QuizIcon from '@mui/icons-material/Quiz';

import { Link } from "react-router-dom";

// Atomic
import Avatar from '../molecules/avatar';
import AvatarHorizontal from '../molecules/avatarHorizontal';
import ListSubheader from '../atoms/listSubheader';
import ListHeader from '../molecules/listHeader';
import HorizontalLogo from '../atoms/horizontalLogo';
import ScrollableTabsLink from '../molecules/scrollableTabsLink';
import AlertsAndInsightsControl from '../molecules/alertsAndInsightsControl';


// Qlik
import GlobalContext from '../../qlik/Context';
import {clearQlikFilters} from '../../qlik/models';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  overflowX: 'hidden',

  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  width: `calc(${theme.spacing(7)} + 1px)`,
  overflowX: 'hidden',

  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const smallScreenMixin = () => ({
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  minHeight: 'auto',
});

const largeScreenMixin = () => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
  minHeight: 64,
});

const DrawerHeader = styled('div', { shouldForwardProp: (prop) => prop !== 'isSmallScreen' })(
  ({isSmallScreen}) => ({
    display: 'flex',
    ...(isSmallScreen && { ...smallScreenMixin() }),
    ...(!isSmallScreen && { ...largeScreenMixin() }),
  })
);

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.palette.surface.main}`,
    boxShadow: theme.customShadows[0],
    ...(open && {
      marginLeft: drawerWidth,
      width: `100%`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),

    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const {app, user, Logout} = useContext(GlobalContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // const userCompanyName = user ? user : "...";

  const handleClearFilters = () => {
    clearQlikFilters(app);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const smView = () => {
    return (
      <>
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{backgroundColor: '#FFFFFF'}}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ marginRight: 'auto', color: 'onSurface.main' }}
            >
              <MenuIcon />
            </IconButton>
            <HorizontalLogo asLink style={{margin: 'auto'}} />
            {/* <IconButton
              aria-label="log out"
              onClick={() => {}}
              edge="end"
              sx={{ marginLeft: 'auto', color: 'onSurface.main', visibility: 'hidden' }}
            >
              <LogoutIcon />
            </IconButton> */}
            <AlertsAndInsightsControl />
          </Toolbar>

          <div style={{width: '100%'}}> 
            <ScrollableTabsLink />
          </div>
        </AppBar>

        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}

          sx={{zIndex: theme.zIndex.drawer + 2}}
        >
          <DrawerHeader sx={{width: drawerWidth, minHeight: 'auto'}} isSmallScreen={isSmallScreen}>
            <IconButton aria-label="close drawer" onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
            <AvatarHorizontal name={user} open={open}/>
          </DrawerHeader>

          <List subheader={(<ListHeader/>)}>
            <ListItem>
              <Button fullWidth onClick={handleClearFilters} variant="text" title="Clear All Filters" size="large" sx={{ color: 'onSurface.dark', textAlign: 'left'}}>Clear Filters</Button>          
            </ListItem>
          </List>

          <Divider variant="middle" sx={{m: 2}} />
          
          <List subheader={(<ListSubheader>Utils</ListSubheader>)}>
            {['Glossary', 'FAQ'].map((text, index) => (
              <ListItem button key={text}
              component="a"
              href={`/utils/${text.toLowerCase()}`}>
                <ListItemIcon>
                  {index % 2 === 0 ? <DataObjectIcon /> : <QuizIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>

        </SwipeableDrawer>
      </>
    );
  };

  const lgView = () => {
    return (
      <>
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{backgroundColor: '#FFF'}}>
            <HorizontalLogo asLink></HorizontalLogo>
            <ScrollableTabsLink sx={{ml: 20}}/>
            {/* <IconButton
              size="large"
              aria-label="log out"
              onClick={Logout}
              edge="end"
              sx={{ marginLeft: 'auto' , visibility: 'hidden', display: 'none'}}
            >
              <LogoutIcon />
            </IconButton> */}
            <AlertsAndInsightsControl />
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <DrawerHeader sx={{mt: 8}}>
            <IconButton
              size="small"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{
                position: 'absolute',
                top: 72,
                right: 8,
              }}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Avatar name={user} open={open}/>
          </DrawerHeader>

          <Divider variant="middle" sx={{m: 2}} />

          <List subheader={(open && <ListHeader/>)}>
            <ListItem>
              {
                open ?
                  <Button
                    onClick={handleClearFilters}
                    fullWidth
                    variant="text"
                    size="large"
                    sx={{ color: 'onSurface.dark', textAlign: 'left'}}>
                      Clear Filters
                  </Button>        
                :
                  <IconButton
                    size="small"
                    title="Clear All Filters"
                    aria-label="clear filters"
                    onClick={handleClearFilters}
                    edge="start"
                    sx={{
                      // position: 'absolute',
                      // top: 72,
                      // right: 8,
                    }}
                  >
                    <FilterAltOffIcon />
                  </IconButton>
              }
            </ListItem>
          </List>

          <Divider variant="middle" sx={{m: 2}} />
          
          <List subheader={(open && <ListSubheader>Utils</ListSubheader>)}>
            {['Glossary', 'FAQ'].map((text, index) => (
              <ListItemButton
              component={Link}
              to={`utils/${text.toLowerCase()}`}
              // href={`/utils/${text.toLowerCase()}`}
              // selected={window.location.pathname === `/utils/${text.toLowerCase()}`}
              key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <DataObjectIcon /> : <QuizIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            ))}
          </List>

        </Drawer>
      </>
      
    );
  };

  return ( isSmallScreen ? smView() : lgView() );
}

export default Sidebar;