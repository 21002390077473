import { PropTypes } from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';


const DivPageHeader = styled('div', { shouldForwardProp: (prop) => prop !== 'isSmallScreen' })(
  ({isSmallScreen}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 40,
    marginTop: isSmallScreen ? 140 : 100
}));

const CompanyName = styled('span')({
  fontWeight: '900', 
  display: 'inline-block',
  // textTransform: 'lowercase',
  wordBreak: 'break-all',

  // '&:first-letter': {
  //   textTransform: 'capitalize',
  // }
});

const PageHeader = ({title, lastUpdate}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <DivPageHeader isSmallScreen={isSmallScreen}>
      <Typography
        sx={{
          color: 'onSurface.main',
          fontWeight: '500',
          fontSize: 20,
        }}
      >
        <span style={{letterSpacing: '1px'}}> Clients Analytics </span>
        { title ?
            <>
              <span style={{fontWeight: '300'}}> for </span>
              <CompanyName> { title } </CompanyName>
            </>
          : null
        }
      </Typography>
      <Typography sx={{color: 'onSurface.light'}}>
        { lastUpdate ? `Last updated in ${lastUpdate}` : <Skeleton width={240} variant="text"/> }
      </Typography>
    </DivPageHeader>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  lastUpdate: PropTypes.string.isRequired,
};

export default PageHeader;