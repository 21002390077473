import {today} from '../components/helper';
import {createStaticRanges} from 'react-date-range';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfYear,
  endOfYear,
  addYears
} from 'date-fns';

export const defineds = {
  endOfToday: endOfDay(today()),
  startOfYesterday: startOfDay(addDays(today(), -1)),
  endOfYesterday: endOfDay(addDays(today(), -1)),
  startOfMonth: startOfMonth(today()),
  endOfMonth: endOfMonth(today()),
  startOfLastMonth: startOfMonth(addMonths(today(), -1)),
  endOfLastMonth: endOfMonth(addMonths(today(), -1)),
  startOfLast30Days: startOfDay(addDays(today(), -30)),
  startOfLast60Days: startOfDay(addDays(today(), -60)),
  startOfLast90Days: startOfDay(addDays(today(), -90)),
  startOfThisYear: startOfYear(today()),
  endOfThisYear: endOfYear(today()),
  startOfLastYear: startOfYear(addYears(today(), -1)),
  endOfLastYear: endOfYear(addYears(today(), -1)),
  startOfAllTime: startOfYear(new Date(2020, 10, 13))
};

export const customStaticRanges = createStaticRanges([
  {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: defineds.startOfLast30Days,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 60 Days',
    range: () => ({
      startDate: defineds.startOfLast60Days,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 90 Days',
    range: () => ({
      startDate: defineds.startOfLast90Days,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'This Year',
    range: () => ({
      startDate: defineds.startOfThisYear,
      endDate: defineds.endOfThisYear,
    }),
  },
  {
    label: 'Last Year',
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    }),
  },
  {
    label: 'All Time',
    range: () => ({
      startDate: defineds.startOfAllTime,
      endDate: defineds.endOfToday,
    }),
  },
]);

export const rangeColors = ['#003C6E', '#78DCD2', '#FF7378'];