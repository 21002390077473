import { PropTypes } from 'prop-types';
import { DateRange } from 'react-date-range';
import { rangeColors } from '../../../constants/dateRangePicker';


const CustomRange = ({onChange, ranges, ...props}) => {
  return (
    <DateRange
      onChange={onChange}
      ranges={ranges}
      rangeColors={rangeColors}
      {...props}
      editableDateInputs
      className="cdrp-swap"
    />
  );
};

CustomRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  ranges: PropTypes.arrayOf(PropTypes.object).isRequired,
  props: PropTypes.object
};

export default CustomRange;