import React from 'react';
import { Outlet } from "react-router-dom";

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import Sidebar from './sidebar';

import Css from '../../cssOverride.css';

const Layout = () => {
  return (
    <Box sx={{ display: 'flex', overflow: 'auto', backgroundColor: 'surface.light', minHeight: '100vh' }}>
      <CssBaseline />

      <Sidebar/>
      <Outlet />

    </Box>
  );
}

export default Layout;