import * as React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

import ListSubheader from '../atoms/listSubheader';

// prob dont need styled comps
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: '50%',
    background: theme.palette.secondary.main,
    color: '#fff',
    borderRadius: 4,
    padding: '4px 8px',
  },
}));

const ListHeader = () => {
  return (
    // <StyledBadge badgeContent={4} color="secondary">
      <ListSubheader> Filters </ListSubheader>
    // </StyledBadge>
  );
}

export default ListHeader;