import React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import GlobalValuesProvider from './components/qlik/Provider';
import CustomRoutes from './components/atomic/organisms/customRoutes';


const theme = createTheme({
  palette: {
    primary: {
      main: '#141E50',
      light: '#003C6E',
      dark: '#0E1539',
      contrastText: '#f2f2f2'
    },
    secondary: {
      main: '#78DCD2',
      light: '#AFE1E1',
      dark: '#00B9A5',
      [30]: '#A0E7DF',
      [50]: '#BBEEE8',
      [75]: '#DDF6F4',
      [85]: '#EBFAF8',
      contrastText: '#FFFFFF'
    },
    grey: {
      main: '#666666',
      '50': '#eceff1',
      '100': '#cfd8dc',
      '200': '#b0bec5',
      '300': '#90a4ae',
      '400': '#78909c',
      '500': '#607d8b',
      '600': '#546e7a',
      '700': '#455a64',
      '800': '#37474f',
      '900': '#263238',
      'A100': '#cfd8dc',
      'A200': '#b0bec5',
      'A400': '#78909c',
      'A700': '#455a64',
    },
    accent: {
      main: '#FF7378',
      light: '#FFB4AA',
      dark: '#BF5357',
      [30]: '#FF9DA0',
      [50]: '#FFB9BB',
      [75]: '#FFDCDD',
      [85]: '#FFEAEB'
    },
    caution: {
      main: '#FFB259',
      [30]: '#FFC98B',
      [50]: '#FFD8AC',
      [75]: '#FFECD5',
      [85]: '#FFF3E6'
    },
    aux: {
      main: '#3E7EAC',
      [30]: '#78A5C5',
      [50]: '#9FBFD6',
      [75]: '#CFDFEA',
      [85]: '#E2ECF3'
    },
    surface: {
      main: '#D2D2D2',
      light: '#F2F2F2',
      dark: '#E0E0E0',
    },
    onSurface: {
      main: '#141E50',
      light: '#B9BCCB',
      dark: '#5B6285',
      middle: '#7C819A'
    },
    brand: {
      main: '#141e50',
      light: '#003c6e',
      dark: '#0B102D',
      contrastText: '#d2d2d2'
    },
  },

  shadows: [
    'none',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 2px 4px 0px #D2D2D24D',
  ],

  customShadows: [
    '0px 4px 4px 0px #D2D2D229',
    '0px 2px 4px 0px #D2D2D24D',
    '0px 0px 0px 1px #3F3F440D'
  ],
  
  typography: {
    fontFamily: [
      'Ubuntu',
      'sans-seriff',
    ].join(','),
  }
});


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalValuesProvider>
        <CustomRoutes />
      </GlobalValuesProvider>

    </ThemeProvider>
  );
}

export default App;