import { useState, useContext } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DateRangeIcon from '@mui/icons-material/DateRange';

// Qlik
import GlobalContext from '../../qlik/Context';

// Atomic
import DateRangePicker from '../molecules/dateRangePicker';
import IconLabelButton from '../atoms/iconLabelButton';

// Helper
import { formatDate, today } from '../../helper';


const CalendarModal = () => {  
  const { filters } = useContext(GlobalContext);
  
  const [{startDate, endDate}] = filters?.dateFilter;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const styles = {
    dialogPaper: {
      backgroundColor: '#FFFFFF',
      border: '1px solid',
      borderColor: theme.palette.surface.dark,
      borderRadius: 3,
      boxShadow: 24,
      minHeight: 385,
      ...isSmallScreen && {
        margin: '16px',
        width: '100%'
      }
    },

    actionButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      maxWidth: '200px',
      margin: '16px 16px 16px auto',
      textTransform: 'capitalize',

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark, 
      }
    }
  }

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconLabelButton onClick={handleOpen} aria-label="open calendar modal" key="filterCalendar" id="filter-calendar" startIcon={<DateRangeIcon/>}>
        { `${formatDate(startDate ?? today())} - ${formatDate(endDate ?? today())}` }
      </IconLabelButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="date-range"
        aria-describedby="date-range"
        PaperProps={{ sx: styles.dialogPaper }}
      >
        <DateRangePicker/>
        <Button variant="primary" sx={styles.actionButton} onClick={handleClose}>Close</Button>
      </Dialog>
    </>
  );
}

export default CalendarModal;