import React, { useEffect, useState, useContext } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

// Atomic
import PageHeader from '../atoms/pageHeader';

// Qlik
import GlobalContext from '../../qlik/Context';

const REPORT = process.env.REACT_APP_TABLE_IFRAME;

const IFRAMES = {
  EXPORT_0: process.env.REACT_APP_EXPORT_IFRAME_0,
  EXPORT_1: process.env.REACT_APP_EXPORT_IFRAME_1,
  EXPORT_2: process.env.REACT_APP_EXPORT_IFRAME_2,
  EXPORT_3: process.env.REACT_APP_EXPORT_IFRAME_3,
  EXPORT_4: process.env.REACT_APP_EXPORT_IFRAME_4,
}

const tableCardStyles = {
  minHeight: 600,
  width: 'auto',
  p: 0,
  border: '1px solid',
  borderColor: 'surface.dark',
};

const tablesCardTitleStyles = {
  fontSize: 18,
  fontWeight: '500',
  color: '#00000099'
}

const ExportButton = styled('div', { shouldForwardProp: (prop) => prop !== 'isSmallScreen' })(
  ({theme, isSmallScreen}) => ({
    display: 'block',
    height: '46px',
    width: isSmallScreen ? '100%' : '180px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.surface.dark,
    background: 'white'
}));

const ExportTableTitle = styled('p')(
  ({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 14,
    marginTop: 32
}))

const TableReport = () => {
  const [viz, setViz] = useState([]);

  const {nebula, lastUpdate, user} = useContext(GlobalContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const destroyOldVisualizations = () => {
    if (!viz.length) return;

    for (let i=0; i>viz.length; i++) {
      viz[0].destroy();
    }
    
    setViz([]);
  };

  const update = () => {
    destroyOldVisualizations();

    const table = <iframe src={REPORT} style={{border: 'none', width: '100%', height: '100%'}}></iframe>

    setViz([
      table,
    ]);
  };

  useEffect(() => {
    if (nebula) update();
  }, [nebula]); // eslint-disable-line


  return (
    <Box component="main" sx={{ flexGrow: 1, py: 3, px: isSmallScreen ? 2 : 5, width: '100%' }}>
      <PageHeader title={user} lastUpdate={lastUpdate}/>

      <ExportTableTitle> Export in CSV </ExportTableTitle>
      
      <Grid container spacing={2}>
        {[0, 1, 2, 3, 4].map((i) => (
          <Grid item key={i} sx={{width: isSmallScreen ? '100%' : 'auto'}}>
            <ExportButton role="button" isSmallScreen={isSmallScreen}>
              <iframe src={IFRAMES[`EXPORT_${i}`]} id={`export-iframe-${i}`} style={{border: 'none', width: '100%', height: '100%'}}></iframe>
            </ExportButton>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} sx={{mt: 4}}>
        <Grid item xs={12}>
          <Card sx={tableCardStyles}>
            <CardHeader title={'Report'} sx={{borderBottom: '1px solid', borderBottomColor: 'surface.dark'}} titleTypographyProps={tablesCardTitleStyles}/>
            <CardContent sx={{height: 800, p: 2, width: 'auto'}} id={`t1`}>
              <iframe src={REPORT} style={{border: 'none', width: '100%', height: '95%'}}></iframe>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </Box>
  );
}

export default TableReport;