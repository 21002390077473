import {useState, useEffect, useContext} from 'react';

import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';

// Atomic
import AlertsAndInsights from '../molecules/alertsAndInsights';
import TextAndBadge from '../atoms/textAndBadge';

// Qlik
import GlobalContext from '../../qlik/Context';
import {getLayoutText, getTableContent} from '../../qlik/models';

import {statusColors} from '../../../constants/statusColors';


const conversionRateStatus = (value) => {
  if (value[0] !== '-') return 'good';

  const normal = value.replace(',', '.');
  const abs = Math.abs(parseFloat(normal));

  if (abs >= 25) return 'warning';
  return 'caution';
};

const weeklyComparisonStatus = (value) => {
  const normal = value.replace(',', '.');

  if (value[0] === '-') return 'warning';
  if (parseFloat(normal) === 0) return 'caution';
  return 'good';
};

const AlertsAndInsightsControl = () => {
  const {app} = useContext(GlobalContext);
  const [notifications, setNotifications] = useState({});
  const [loading, setLoading] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkForNewClients = (clientsList) => {
    if ((clientsList.length === 0) || (clientsList === null) || (clientsList === undefined)) return 0;
    return clientsList.length;
  };

  const getNotifications = async () => {
    if (!app) return;

    const conversionRate = await getLayoutText(app, 'aJnmAYA');
    const weeklyComparison = await getLayoutText(app, 'djJaptR');
    const newClientsList = await getTableContent(app, 'djfQt');

    const notificationsObj = {
      conversionRate: {
        value: conversionRate,
        status: conversionRateStatus(conversionRate),
      },
      weeklyComparison: {
        value: weeklyComparison,
        status: weeklyComparisonStatus(weeklyComparison)
      },
      newClients: {
        value: checkForNewClients(newClientsList),
        status: 'info',
        extraContent: newClientsList
      }
    };

    setNotifications(notificationsObj);

    setLoading(false);
  };

  useEffect(() => {
    getNotifications();
  }, [app]);

  return (
    <>
      <IconButton
        disabled={loading}
        onClick={handleOpen}
        edge="end"
        aria-label="notifications-menu"
        aria-controls={open ? 'notifications-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size="large"
        sx={{ marginLeft: 'auto' }}
      >
        <TextAndBadge color={statusColors(notifications)}>
          <NotificationsIcon />
        </TextAndBadge>
      </IconButton>
      { !loading ?
        <AlertsAndInsights notifications={notifications} statusColor={statusColors(notifications)} anchorEl={anchorEl} open={open} handleClose={handleClose}/>
      : null }
    </>
  );
};

export default AlertsAndInsightsControl;