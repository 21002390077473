import { PropTypes } from 'prop-types';

import { styled } from '@mui/material/styles';


const MiniTitle = styled('span', {shouldForwardProp: (prop) => prop !== 'textTransform'})(
  ({ theme, textTransform }) => ({
    textTransform: textTransform,
    fontSize: 13,
    color: theme.palette.onSurface.light,
    fontWeight: 500
}));


const SmallTitle = ({children, textTransform = "none"}) => {

  return (
    <MiniTitle textTransform={textTransform}>
      {children}
    </MiniTitle>
  );
};

SmallTitle.propTypes = {
  children: PropTypes.node.isRequired,
  textTransform: PropTypes.string
};

export default SmallTitle;