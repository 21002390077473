import { useState, useEffect, useContext } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

// Atomic
import PageHeader from '../atoms/pageHeader';
import CalendarModal from '../molecules/calendarModal';
import KpiBlock from '../organisms/kpiBlock';

// Qlik
import GlobalContext from '../../qlik/Context';


const TableCardContent = styled(CardContent)(
  ({ size }) => ({
    height: size === 1 ? 800 : 200,
    width: 'auto',
    padding: 0,

    '&:last-child': {
      paddingBottom: 0
    }
  })
);

const styles = {
  chartsCardStyles: {
    minHeight: 400,
    width: 'auto',
    p: 0,
    border: '1px solid',
    borderColor: 'surface.dark',
  },
   
  chartsCardTitleStyles: {
    fontSize: 18,
    fontWeight: '500',
    color: '#00000099'
  },

  tableCardStyles: {
    minHeight: 'fit-content',
    width: 'auto',
    p: 0,
    border: '1px solid',
    borderColor: 'surface.dark',
  }
};

const chartsTitle = [
  'TPV-In and TPV-Out',
  'TPV-Out Growth',
  'Transacting Accounts Growth',
  'Top 10 Clients in TPV-Out',
  'Top 10 Clients in Transacting Accounts',
  'Transactions over Time',
  'Transactions vs Response Code',
];

const tablesTitle = [
  'Transactions by Category',
  'Payment Methods',
];

const Overview = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  
  const {nebula, user, lastUpdate, filters} = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [viz, setViz] = useState([]);

  const destroyOldVisualizations = () => {
    if (viz.length) {
      viz.forEach(vsisualization => vsisualization.then(v => v.destroy()));
      setViz([]);
    }
  };

  const update = async () => {
    destroyOldVisualizations();
    setLoading(false);

    const chart_1 = await nebula.render({ element: document.getElementById('c1'), id: 'JFRXQyc' });
    const chart_2 = await nebula.render({ element: document.getElementById('c2'), id: 'FPdcJt' });
    const chart_3 = await nebula.render({ element: document.getElementById('c3'), id: 'MzgdQh' });

    const chart_4 = await nebula.render({ element: document.getElementById('c4'), id: 'wCLmuF', properties: { orientation: 'horizontal' }});
    const chart_5 = await nebula.render({ element: document.getElementById('c5'), id: 'NEQqUR', properties: { orientation: 'horizontal' } });

    const chart_6 = await nebula.render({ element: document.getElementById('c6'), id: 'tNCjuZ' });
    const chart_7 = await nebula.render({ element: document.getElementById('c7'), id: 'USfxFPU' });

    const table_1 = await nebula.render({ element: document.getElementById('t1'), id: 'MQmzLG' });
    const table_2 = await nebula.render({ element: document.getElementById('t2'), id: 'WJgCHD' });

    setViz([
      chart_1,
      chart_2,
      chart_3,
      chart_4,
      chart_5,
      chart_6,
      chart_7,

      table_1,
      table_2
    ]);
  };

  
  useEffect(() => {
    if (nebula) update();
  }, [nebula]); // eslint-disable-line


	return (
    <Box component="main" sx={{ flexGrow: 1, py: 3, px: isSmallScreen ? 2 : 5, width: '100%' }}>
      <PageHeader title={user} lastUpdate={lastUpdate} />
      <CalendarModal />
      <KpiBlock />

      {/* Charts */}

      {/* ECONOMICS Block */}
      <Grid container spacing={2} sx={{mt: 4}}>
        <Grid item xs={12} key={1}>
          <Card sx={styles.chartsCardStyles}>
            <CardHeader title={chartsTitle[0]} sx={{borderBottom: '1px solid', borderBottomColor: 'surface.dark'}} titleTypographyProps={styles.chartsCardTitleStyles}/>
            <CardContent sx={{height: 600, p: 2, width: 'auto'}} id={`c1`}>
              {
                loading && 
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <CircularProgress sx={{color: "onSurface.light"}} />                  
                </div>
              }
            </CardContent>
          </Card>
        </Grid>
        {[2, 3].map((i) => (
          <Grid item xs={12} md={6} key={i} sx={{mt: 2}}>
            <Card sx={styles.chartsCardStyles}>
              <CardHeader title={chartsTitle[i-1]} sx={{borderBottom: '1px solid', borderBottomColor: 'surface.dark'}} titleTypographyProps={styles.chartsCardTitleStyles}/>
              <CardContent sx={{height: 600, p: 2, width: 'auto'}} id={`c${i}`}>
                {
                  loading && 
                  <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress sx={{color: "onSurface.light"}} />                  
                  </div>
                }
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* CLIENT Block */}
      <Grid container spacing={2} sx={{mt: 4}}>
        {[4, 5].map((i) => (
          <Grid item xs={12} md={6} key={i}>
            <Card sx={styles.chartsCardStyles}>
              <CardHeader title={chartsTitle[i-1]} sx={{borderBottom: '1px solid', borderBottomColor: 'surface.dark'}} titleTypographyProps={styles.chartsCardTitleStyles}/>
              <CardContent sx={{height: 600, p: 2, width: 'auto'}} id={`c${i}`}>
                {
                  loading && 
                  <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress sx={{color: "onSurface.light"}} />                  
                  </div>
                }
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* TRANSACTION Block */}
      <Grid container spacing={2} sx={{mt: 4}}>
        {[6, 7].map((i) => (
          <Grid item xs={12} key={i} sx={{mt: 2}}>
            <Card sx={styles.chartsCardStyles}>
              <CardHeader title={chartsTitle[i-1]} sx={{borderBottom: '1px solid', borderBottomColor: 'surface.dark'}} titleTypographyProps={styles.chartsCardTitleStyles}/>
              <CardContent sx={{height: 600, p: 2, width: 'auto'}} id={`c${i}`}>
                {
                  loading && 
                  <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress sx={{color: "onSurface.light"}} />                  
                  </div>
                }
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Tables */}
      <Grid container spacing={2} sx={{mt: 4}}>
        {[1, 2].map((i) => (
          <Grid item key={i} xs={12}>
            <Card sx={styles.tableCardStyles}>
              <CardHeader title={tablesTitle[i-1]} sx={{borderBottom: '1px solid', borderBottomColor: 'surface.dark'}} titleTypographyProps={styles.chartsCardTitleStyles}/>
              <TableCardContent size={i} id={`t${i}`}>
                {
                  loading && 
                  <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress sx={{color: "onSurface.light"}} />                  
                  </div>
                }
              </TableCardContent>
            </Card>
          </Grid>        
        ))}
      </Grid>
    </Box>
	);
}

export default Overview;