import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineds } from '../../constants/dateRangePicker';
import { handleFilter, getLayoutText } from '../qlik/models';
import Context from './Context';
import embed from './configure';
import connect from './connect';

// Helper
import {capitalizeFirstLetter} from '../helper';


export const TENANT = process.env.REACT_APP_QCT;

export const WEBID = process.env.REACT_APP_WID;

export const APPID = process.env.REACT_APP_AID;


const nebulaPromise = async () => {
  const app = await connect({
    url: TENANT,
    webIntegrationId: WEBID,
    appId: APPID,
  });

  if (!app || app === null || app === undefined) {
    console.log(`Error Retreiving Appliction From Server`);
    return null;
  };

  const nebulaEmbedApp = embed(app);

  return {
    nebulaEmbedApp,
    app
  };
};

const GlobalValuesProvider = ({ children }) => {
  const [nebula, setNebula] = useState(null);
  const [app, setApp] = useState(null);
  const [user, setUser] = useState("");
  const [lastUpdate, setLastUpdate] = useState("");

  const [dateFilter, setDateFilter] = useState([{
    startDate: defineds.startOfThisYear,
    endDate: new Date(),
    key: 'selection'
  }]);

  const filters = { dateFilter, setDateFilter };

  const qlikConnection = async () => {
    const {nebulaEmbedApp, app} = await nebulaPromise();

    setNebula(nebulaEmbedApp);
    setApp(app);

    const userName = await getLayoutText(app, 'kncPxzP');
    
    if (userName === 'UPBRASIL') {
      setUser("Up Brasil");
    } else {
      setUser(capitalizeFirstLetter(userName));
    };

    const {startDate, endDate} = dateFilter[0];
    handleFilter({startDate, endDate}, app);
  };

  // Todo: separate concerns;
  const getLastUpdatedAt = async () => {
    const lastUpdatedAt = await getLayoutText(app, 'uScU');
    setLastUpdate(lastUpdatedAt);
  };

  const Logout = () => {
    setUser("");
  };

  useEffect(() => {
    qlikConnection();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!app) return;
    getLastUpdatedAt();
  }, [app]); // eslint-disable-line
 
  return (
    <Context.Provider value={{ user, lastUpdate, filters, Logout, nebula, app }}>
      { children }
    </Context.Provider>
  );
};

GlobalValuesProvider.propTypes = { children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired };
GlobalValuesProvider.defaultProps = [];

export default GlobalValuesProvider;