import { styled } from '@mui/material/styles';
import swap_hor from '../../img/logos/swap_horizontal.svg';

const SendHome = styled('a')({
  display: 'block',
  width: 106,
  height: '30px'
});

const HorizontalLogo = ({style, asLink = false}) => {
  return (
    asLink ? <SendHome href="/"> <img src={swap_hor} style={{...style, maxWidth: 106}}></img> </SendHome>
    : <img src={swap_hor} style={{...style, maxWidth: 106}}></img>    
  );
}

export default HorizontalLogo;