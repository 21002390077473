import swap from '../components/img/logos/swap_round.png';
import alymente from '../components/img/logos/alymente_round.svg';
import payfy from '../components/img/logos/payfy_round.svg';
import localiza from '../components/img/logos/localiza_round.svg';
import swile from '../components/img/logos/swile_round.svg';
import upBrasil from '../components/img/logos/up_brasil.svg';

const logos = {
  "Swap": swap,
  "Alymente": alymente,
  "Payfy": payfy,
  "Localiza": localiza,
  "Vee": swile,
  "Up Brasil": upBrasil
};

export default logos;