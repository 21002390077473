import { PropTypes } from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { Avatar as MuiAvatar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import logos from '../../../constants/logos';


const AvatarInfo = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: 16,
	justifyContent: 'flex-start'
});

const AvatarHorizontal = ({open, name = ""}) => {
	const theme = useTheme();

  return (
    <Box sx={{
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			marginTop: '48px',
			marginBottom: '32px',
    }}>
			<MuiAvatar
				src={logos[name]}
				sx={{ 
					bgcolor: 'onSurface.light'
			}}>
				<Skeleton variant="circular" width={40} height={40} />
			</MuiAvatar>
			{
				open &&
				<AvatarInfo>
					<Typography sx={{ color: 'onSurface.main', mt: 1, textTransform: 'capitalize', display: 'block' }}>
						{ name }
					</Typography>
					<Typography sx={{ color: 'surface.main', ...theme.typography.body2 }}>
						User
					</Typography>
				</AvatarInfo>
			}
    </Box>
  );
};

AvatarHorizontal.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string,
};


export default AvatarHorizontal;
