export const statusColors = (notifications) => {
  const keys = Object.keys(notifications);

  let status = "secondary";

  for (const key of keys) {
    if ((notifications[key].status) === "warning") return "accent";
    if ((notifications[key].status) === "caution") status = "caution";
  };

  return status;
};