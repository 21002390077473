import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const RequestTitle = styled(Typography)(({theme}) => ({
  fontSize: 100,
  fontWeight: '400',
  color: theme.palette.primary.main,

  marginTop: 200,
  borderBottom: `6px solid ${theme.palette.primary.main}`
}));

const NotFoundContainer = styled('div')(() => ({
  margin: 'auto',
  paddingLeft: 32,
  paddingRight: 32
}));

const NoMatch = () => {

  return (
    <NotFoundContainer>
      <RequestTitle> 404 </RequestTitle>
    </NotFoundContainer>
  );
}

export default NoMatch;