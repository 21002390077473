import {useState, useLayoutEffect} from 'react';

import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Helper
import {getLocationHash} from '../../helper';


const QuestionsContainer = styled('div')(
  ({theme}) => ({
    background: 'white',
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.surface.dark,
    boxShadow: theme.customShadows[0],
    padding: '24px 32px',

    '& .MuiAccordionSummary-root.Mui-focusVisible': {
      background: theme.palette.surface.light,
    }
}));

const Code = styled('code')(
  ({theme}) => ({
    backgroundColor: theme.palette.surface.light,
    color: theme.palette.onSurface.dark,
    fontSize: 12,
    fontWeight: '500',
    padding: '2px 4px',
    borderRadius: 6,
    marginLeft: '4px',
    marginRight: '4px',
}));

const UnorderedList = styled('ul')(
  ({theme}) => ({
    color: theme.palette.onSurface.dark,
    marginBottom: theme.spacing(4),
    paddingLeft: 24,
    margin: 0,
    
    '& li': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
}));

const KpisDefinition = styled('span')({
  display: 'block',
  margin: '2px 0 24px 0',
  fontSize: 14,
});


const kpis = () => {
  return (
    <UnorderedList>
      <li>
        TPV-IN
        <KpisDefinition>
          Sum of approved funds in transactions in the period.
        </KpisDefinition>
      </li>
      <li>
        TPV-OUT
        <KpisDefinition>
          Sum of approved transactions for 
          <Code> Goods Purchase </Code>,
          <Code> Purchase Phone Authorized </Code>,
          <Code> Cash Withdrawal </Code>,
          <Code> E-Commerce </Code>,
          <Code> Ted </Code>,
          <Code> Boleto </Code> and 
          <Code> Pix </Code> in the period.
        </KpisDefinition>
      </li>
      <li>
        APPROVED TRANSACTIONS
        <KpisDefinition>
          The count of Approved Transactions in the period.
        </KpisDefinition>
      </li>
      <li>
        DENIED TRANSACTIONS
        <KpisDefinition>
          The count of Denied Transactions in the period.
        </KpisDefinition>
      </li>
      <li>
        OPERATING ACCOUNTS
        <KpisDefinition>
          The distinct count of accounts that made any transactions in the period.
        </KpisDefinition>
      </li>
      <li>
        CLIENTS
        <KpisDefinition>
          The distinct count of operating clients. This count is based on the number of operating treasury account id in the period.  
        </KpisDefinition>
      </li>
      <li>
        MENSAL AVERAGE TPV-OUT BY ACCOUNT
        <KpisDefinition>
          The mensal average TPV-Out by account is calculated as the amount of TPV-Out
          in the period divided by the total operating accounts in the same period.
          Then, the result is divided by the number of months in the period.
        </KpisDefinition>
      </li>
      <li>
        MENSAL AVERAGE GROWTH (TPV-OUT)
        <KpisDefinition>
          The Mensal Average Growth is calculated as the average of the percentual
          growth of TPV-Out by month (exception for the current month) in the period.
        </KpisDefinition>
      </li>
      <li>
        CONVERSTION RATE
        <KpisDefinition>
          The Conversion Rate is calculated as the count of Approved Transactions in
          the period divided by the total count transactions in the same period. 
        </KpisDefinition>
      </li>
      <li>
        AVERAGE TICKET (TPV-OUT)
        <KpisDefinition>
          Average Ticket is calculated as the sum of approved TPV-Out in the period
          divided by the count of the Approved Transactions in the same period. 
        </KpisDefinition>
      </li>
    </UnorderedList>
  );
};

const FaqAccordion = () => {
  const [expanded, setExpanded] = useState(false);

  const questions = [
    {
      topic: 'about',
      summary: 'What is Clients Analytics?',
      details: `
        Clients Analytics is a Swap Data Application that offers an experience about data insights.
        The goal is to provide data intelligence to empower our clients.
      `,
    },
    {
      topic: 'access',
      summary: 'How can I obtain access to Clients Analytics?',
      details: `
        The credentials to access the Panel must be requested from Swap's Analytics Applications Team.
      `,
    },
    {
      topic: 'dataperiod',
      summary: 'What is the period of data on the panel?',
      details: `
        The panel displays data from the beginning of operation to D-1 (day before current).
      `,
    },
    {
      topic: 'dataissue',
      summary: 'Who should I contact in case of doubts or problems about Clients Analytics?',
      details: `
        To report any difficulties related to the Clients Analytics Panel, please contact
        Swap's Analytics Applications Team through the email suporte@contaswap.com
      `,
    },
    {
      topic: 'kpi',
      summary: 'What are the panel KPIs?',
      details: kpis()
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useLayoutEffect(() => {
    const anchor = getLocationHash();

    if (!anchor) return;

    setExpanded(anchor);
  }, []);

  return (
    <QuestionsContainer>
      {
        questions.map((question, index) => {
          return (
            <Accordion
              key={index}
              expanded={expanded === question.topic}
              onChange={handleChange(question.topic)}
              sx={{ boxShadow: 'none' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={question.topic}
                sx={{
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  color: 'onSurface.main'
                }}
              >
                {question.summary}
              </AccordionSummary>
              <AccordionDetails sx={{
                paddingTop: 2,
                paddingBottom: 2,
                color: 'onSurface.middle',
                fontWeight: '300'
              }}>
                {question.details}
              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </QuestionsContainer>
  );
};

export default FaqAccordion;